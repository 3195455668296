// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const mediPlanQuiz = (name: string): any => {
  return {
    Name: name,
    Landing: {
      heading: 'Discover how you can lose weight',
      description: 'by solving inflammation issues',
    },
    Quiz: {
      defaultProperties: [
        {
          question: 'gender',
          answer: name,
        },
      ],
      quizFlow: [
        {
          question:
            'How familiar are you with the <span class="keyword">Mediterranean diet</span>?',
          questionCode: 'familiarity_diet',
          answers: ['Expert', 'Competent', 'Beginner'],
          type: 'question',
        },
        {
          type: 'info',
          description:
            '<h3>What is <span class="keyword">Mediterranean diet?</span></h3>' +
            "<p>The Mediterranean diet is a balanced approach to eating inspired by the Mediterranean region's traditions. This nutrition-focused diet supports not only quick weight loss but also helps manage inflammation, which is often associated with various chronic health issues. By including the right foods, the Mediterranean diet can alleviate symptoms such as weight gain, brain fog, anxiety, and depression, all while enhancing overall well-being.</p>" +
            '<h3><span class="keyword">Benefits</span> of a Mediterranean diet</h3>' +
            '<ul class="quiz-options">' +
            '<li><div class="marker"></div>Sustainable weight loss</li>' +
            '<li><div class="marker"></div>Improved heart health</li>' +
            '<li><div class="marker"></div>Better blood sugar control</li>' +
            '<li><div class="marker"></div>Enhanced cognitive function</li>' +
            '<li><div class="marker"></div>Lower risk of long-term diseases</li>' +
            '</ul>',
          button: 'Continue',
          questionCode: 'diet_info',
        },
        {
          questionCode: 'unusual_symptoms',
          type: 'question',
          question:
            'Are you experiencing skin changes like thinning, easy bruising, acne, or unusual face hair growth?',
          answers: ['Yes', 'No'],
        },
        {
          questionCode: 'brain_fog',
          type: 'question',
          question:
            'Do you feel tired or have trouble focusing, sometimes called "brain fog"?',
          answers: ['Yes', 'No'],
        },
        {
          questionCode: 'random_weight',
          type: 'question',
          question:
            'Have you noticed gaining weight for no clear reason, especially around your stomach?',
          answers: ['Yes', 'No'],
        },
        {
          questionCode: 'hungry',
          type: 'question',
          question:
            'Do you still feel unusually hungry even after eating a full meal?',
          answers: ['Yes', 'No'],
        },
        {
          questionCode: 'efforts',
          type: 'question',
          question:
            'Do you find it hard to lose weight, even when you try?',
          answers: ['Yes', 'No'],
        },
        {
          type: 'input',
          heading: 'What is your <span class="keyword">height</span>?',
          questionCode: 'height_input',
          button: 'Continue',
          param: 'height',
        },
        {
          type: 'input',
          heading: 'What is your <span class="keyword">weight</span>?',
          questionCode: 'weight_input',
          button: 'Continue',
          param: 'weight',
        },
        {
          type: 'input',
          heading: 'What is your <span class="keyword">target weight</span>?',
          questionCode: 'target_weight_input',
          button: 'Continue',
          param: 'desired_weight',
        },
        {
          type: 'input',
          heading: 'What is your <span class="keyword">age</span>?',
          questionCode: 'age_input',
          button: 'Continue',
          param: 'age',
        },
        {
          questionCode: 'activity_level',
          type: 'question',
          question: 'How physically <span class="keyword">active</span> are you?',
          subtitle:
            'Your personalized plan will be adjusted based on your physical activity level',
          answers: [
            'Very active (3-4 times per week)',
            'Active (1-2 times per week)',
            'Not active',
          ],
        },
        {
          type: 'info',
          description:
            '<h3><span class="keyword">MediPlan</span> simplifies weight loss by making it easy to stay committed without effort.</h3>' +
            '<div class="comment">' +
            `<img src="/static/images/${name === 'male' ? 'james' : 'olivia'}.png" />` +
            (name === 'male'
              ? '<p><span class="keyword">MediPlan</span> made it incredibly easy for me to stick to a diet, maintain consistent healthy food choices, and keep the weight off without regaining it.</p>'
              : '<p>Using <span class="keyword">MediPlan</span> made it effortless to stick to my diet, maintain healthy eating habits, and stay consistent with my weight loss goals, all without regaining any of the weight I lost.</p>') +
            (name === 'male'
              ? '<p><strong>Alex, Huston</strong></p>'
              : '<p><strong>Susan, Philadelphia</strong></p>') +
            '</div>',
          button: 'Continue',
          questionCode: 'commitment_info',
        },
        {
          questionCode: 'sleep',
          type: 'question',
          question: 'How much do you usually <span class="keyword">sleep</span>?',
          answers: [
            'Less than 5 hours',
            '5-6 hours',
            '7-8 hours',
            'More than 8 hours'
          ],
        },
        {
          question: 'How much water do you usually drink?',
          subtitle: 'We mean clean water, excluding coffee, tea, and other drinks',
          questionCode: 'daily_water_intake',
          answers: [
            {
              text: 'Only coffee or tea',
              image: '/static/images/coffee.svg',
              explainer: {
                title: '💧 Drinking water is essential',
                description:
                  "If you're not hydrated, your body can't perform at its highest level. MediPlan will remind you to drink enough water.",
              },
            },
            {
              text: 'Less than 2 glasses (16 Oz)',
              image: '/static/images/water1.svg',
              explainer: {
                title: '💧 Drinking water is essential',
                description:
                  "If you're not hydrated, your body can't perform at its highest level. MediPlan will remind you to drink enough water.",
              },
            },
            {
              text: '2-6 glasses (16-48 Oz)',
              image: '/static/images/water2.svg',
              explainer: {
                title: '💧 Wow! Impressive!',
                description: 'You drink more water than 75% of users*. Keep it up!',
                disclaimer: '*users of MediPlan who took the quiz.',
              },
            },
            {
              text: '7-10 glasses (56-80 Oz)',
              image: '/static/images/water3.svg',
              explainer: {
                title: '💧 Wow! Impressive!',
                description: 'You drink more water than 75% of users*. Keep it up!',
                disclaimer: '*users of MediPlan who took the quiz.',
              },
            },
            {
              text: "I don't count, it depends",
              image: '/static/images/dunno.svg',
            },
          ],
          type: 'question',
          isExplainerSelect: true,
        },
        {
          questionCode: 'diseases',
          type: 'question',
          question:
            'Does <span class="keyword">any of the following</span> apply for you?',
          answers: [
            {
              emphasized: true,
              text: '<img src="/static/icons/check-green.svg" width="32" height="32" /><p>None</p>',
            },
            '<img src="/static/icons/diabetes.svg" /><p>Diabetes (any stage)</p>',
            '<img src="/static/icons/blood-pressure.svg" /><p>High blood pressure</p>',
            '<img src="/static/icons/heart.svg" /><p>Heart diseases</p>',
            '<img src="/static/icons/kidney.svg" /><p>Kidney diseases</p>',
            '<img src="/static/icons/liver.svg" /><p>Liver disease</p>',
            '<img src="/static/icons/cholesterol.svg" /><p>High cholesterol</p>',
            '<img src="/static/icons/gastritis.svg" /><p>Gastritis</p>',
            '<img src="/static/icons/spine.svg" /><p>Back pain</p>',
            '<img src="/static/icons/knee.svg" /><p>Joint or knee pain</p>',
            '<img src="/static/icons/lung.svg" /><p>Asthma or lung disease</p>',
            {
              customText: true,
              text: '<img src="/static/icons/check.svg" width="32" height="32" /><p>Other</p>',
            },
          ],
          isMultiSelect: true,
        },
        // {
        //   type: 'info',
        //   description:
        //     '<h3><span class="keyword">Better metabolism</span> leads to longevity and health</h3>' +
        //     '<div class="comment">' +
        //     '<ul class="quiz-options">' +
        //     '<li><img src="/static/icons/scales.svg" /><p>Research shows that reducing body fat by just 12% can extend lifespan by <strong>6.8 years.</strong></p></li>' +
        //     '<li><img src="/static/icons/heart.svg" /><p>Lower A1C levels reduces metabolic syndrome by 14x, which <strong>lowers risk of heart diseases.</strong></p></li>' +
        //     '<li><img src="/static/icons/needle.svg" /><p>Diabetes is the <strong>leading cause of death</strong> among overweight Americans.</p></li>' +
        //     '</ul>' +
        //     '</div>' +
        //     '<div class="info-card"><p>*</p><img src="/static/images/nih.png" /><p>National institute of health</p></div>',
        //   button: 'Continue',
        //   questionCode: 'metabolism_info',
        // },
        // {
        //   type: 'question',
        //   question: 'What are your <span class="keyword">goals</span>?',
        //   questionCode: 'goals',
        //   subtitle:
        //     'Your program is going to be personalized to reach your desired results',
        //   answers: [
        //     '<img src="/static/icons/weight.svg" /><p>Lose weight</p>',
        //     '<img src="/static/icons/heart.svg" /><p>Improve heart health</p>',
        //     '<img src="/static/icons/energy.svg" /><p>Increase energy & focus</p>',
        //     '<img src="/static/icons/joints.svg" /><p>Reduced joint & body pain</p>',
        //     '<img src="/static/icons/blood-sugar.svg" /><p>Reduce blood sugar</p>',
        //     '<img src="/static/icons/mental.svg" /><p>Improve mental health</p>',
        //     '<img src="/static/icons/time.svg" /><p>Slow aging process</p>',
        //     '<img src="/static/icons/sleep.svg" /><p>Improve sleep quality</p>',
        //   ],
        //   isMultiSelect: true,
        // },
        // {
        //   questionCode: 'meal_time',
        //   type: 'question',
        //   question:
        //     'How much <span class="keyword">time</span> do you have for meal preparation each day?',
        //   answers: ['30 minutes or less', 'Up to 1 hour', 'More than 1 hour'],
        // },
        // {
        //   questionCode: 'dietary_restrictions',
        //   type: 'question',
        //   question: 'Do you have any dietary <span class="keyword">restrictions</span>?',
        //   answers: [
        //     {
        //       emphasized: true,
        //       text: '<img src="/static/icons/check-green.svg" width="32" height="32" /><p>I eat everything</p>',
        //     },
        //     '<img src="/static/icons/fish.svg" width="32" height="32" /><p>I am pescetarian</p>',
        //     '<img src="/static/icons/milk.svg" width="32" height="32" /><p>I am vegetarian</p>',
        //     '<img src="/static/icons/vegan.svg" width="32" height="32" /><p>I am vegan</p>',
        //   ],
        // },
        // {
        //   questionCode: 'alergies',
        //   type: 'question',
        //   question:
        //     'What kind of <span class="keyword">allergies</span> do you have, if any?',
        //   subtitle: 'Selected food will be excluded from your meal plan',
        //   answers: [
        //     {
        //       emphasized: true,
        //       text: '<img src="/static/icons/check-green.svg" width="32" height="32" /><p>None</p>',
        //     },
        //     '<img src="/static/icons/milk.svg" /><p>Milk/Lactose</p>',
        //     '<img src="/static/icons/soy.svg" /><p>Soy</p>',
        //     '<img src="/static/icons/shellfish.svg" /><p>Shellfish</p>',
        //     '<img src="/static/icons/vegan.svg" /><p>Gluten</p>',
        //     '<img src="/static/icons/fish.svg" /><p>Fish</p>',
        //     '<img src="/static/icons/tree-nuts.svg" /><p>Tree nuts</p>',
        //     '<img src="/static/icons/peanuts.svg" /><p>Peanuts</p>',
        //     '<img src="/static/icons/eggs.svg" /><p>Eggs</p>',
        //   ],
        //   isMultiSelect: true,
        // },
        // {
        //   questionCode: 'veggies',
        //   type: 'question',
        //   question: 'Which <span class="keyword">veggies</span> do you not eat?',
        //   subtitle: 'Selected food will be excluded from your meal plan',
        //   answers: [
        //     {
        //       emphasized: true,
        //       text: '<img src="/static/icons/check-green.svg" width="32" height="32" /><p>I eat them all</p>',
        //     },
        //     '<img src="/static/icons/turnip.svg" /><p>Turnip</p>',
        //     '<img src="/static/icons/artichoke.svg" /><p>Artichoke</p>',
        //     '<img src="/static/icons/beet.svg" /><p>Beets</p>',
        //     '<img src="/static/icons/radish.svg" /><p>Radish</p>',
        //     '<img src="/static/icons/broccoli.svg" /><p>Broccoli</p>',
        //     '<img src="/static/icons/brussels-sprouts.svg" /><p>Brussels sprouts</p>',
        //     '<img src="/static/icons/potatoes.svg" /><p>Potatoes</p>',
        //     '<img src="/static/icons/carrot.svg" /><p>Carrots</p>',
        //   ],
        //   isMultiSelect: true,
        // },
        // {
        //   questionCode: 'carbs',
        //   type: 'question',
        //   question:
        //     'Which <span class="keyword">carbs</span> and starchy foods do you not eat?',
        //   subtitle: 'Selected food will be excluded from your meal plan',
        //   answers: [
        //     {
        //       emphasized: true,
        //       text: '<img src="/static/icons/check-green.svg" width="32" height="32" /><p>I eat them all</p>',
        //     },
        //     '<img src="/static/icons/sorghum.svg" /><p>Sorghum</p>',
        //     '<img src="/static/icons/couscous.svg" /><p>Couscous</p>',
        //     '<img src="/static/icons/sweet-potatoes.svg" /><p>Sweet potatoes</p>',
        //     '<img src="/static/icons/pasta.svg" /><p>Pasta</p>',
        //     '<img src="/static/icons/rice.svg" /><p>Rice</p>',
        //     '<img src="/static/icons/bread.svg" /><p>Bread</p>',
        //     '<img src="/static/icons/potatoes.svg" /><p>Potatoes</p>',
        //   ],
        //   isMultiSelect: true,
        // },
        // {
        //   questionCode: 'fruits',
        //   type: 'question',
        //   question: 'Which <span class="keyword">fruits</span> do you not eat?',
        //   subtitle: 'Selected food will be excluded from your meal plan',
        //   answers: [
        //     {
        //       emphasized: true,
        //       text: '<img src="/static/icons/check-green.svg" width="32" height="32" /><p>I eat them all</p>',
        //     },
        //     '<img src="/static/icons/grapefruits.svg" /><p>Grapefruits</p>',
        //     '<img src="/static/icons/bananas.svg" /><p>Bananas</p>',
        //     '<img src="/static/icons/lemons.svg" /><p>Lemons</p>',
        //     '<img src="/static/icons/watermelon.svg" /><p>Watermelon</p>',
        //     '<img src="/static/icons/strawberries.svg" /><p>Strawberries</p>',
        //     '<img src="/static/icons/oranges.svg" /><p>Oranges</p>',
        //     '<img src="/static/icons/grapes.svg" /><p>Grapes</p>',
        //     '<img src="/static/icons/apples.svg" /><p>Apples</p>',
        //   ],
        //   isMultiSelect: true,
        // },
        // {
        //   questionCode: 'ingredients',
        //   type: 'question',
        //   question: 'Which <span class="keyword">ingredients</span> do you dislike?',
        //   subtitle: 'Selected food will be excluded from your meal plan',
        //   answers: [
        //     {
        //       emphasized: true,
        //       text: '<img src="/static/icons/check-green.svg" width="32" height="32" /><p>I like them all</p>',
        //     },
        //     '<img src="/static/icons/avocados.svg" /><p>Avocados</p>',
        //     '<img src="/static/icons/mushrooms.svg" /><p>Mushrooms</p>',
        //     '<img src="/static/icons/bell-peppers.svg" /><p>Bell peppers</p>',
        //     '<img src="/static/icons/onions.svg" /><p>Onions</p>',
        //     '<img src="/static/icons/nuts.svg" /><p>Nuts</p>',
        //     '<img src="/static/icons/red-meat.svg" /><p>Red meat</p>',
        //     '<img src="/static/icons/cheese.svg" /><p>Cheese</p>',
        //     '<img src="/static/icons/eggs.svg" /><p>Eggs</p>',
        //     '<img src="/static/icons/butter.svg" /><p>Butter</p>',
        //     '<img src="/static/icons/poultry.svg" /><p>Poultry</p>',
        //   ],
        //   isMultiSelect: true,
        // },
        // {
        //   questionCode: 'meal_quantity',
        //   type: 'question',
        //   question: 'Preferable amount of <span class="keyword">meals per day</span>',
        //   subtitle: 'Your meal plan will be personalized accordingly',
        //   answers: [
        //     '2 meals (Breakfast + Lunch)',
        //     '2 meals (Breakfast + Dinner)',
        //     '2 meals (Lunch + Dinner)',
        //     '3 meals',
        //     '4 meals (3 meals and 1 snack)',
        //     '5 meals (3 meals and 2 snacks)',
        //     {
        //       customText: true,
        //       text: 'Other',
        //     },
        //   ],
        // },
        {
          type: 'info',
          description:
            '<h3><span class="keyword">MediPlan</span> delivers sustainable results by focusing on habit and behavior changes, rather than restrictive dieting</h3>' +
            '<div class="description-image-container"><img src="/static/images/graph.png" class="description-image" /></div>' +
            '<div class="info-card"><p>*In a 6-month research study, 94% of our users reached their ideal weight with MediPlan and successfully maintained it afterward.</p></div>',
          button: 'Continue',
          questionCode: 'projection_info',
        },
      ],
    },
    GetStarted: {
      description:
        'Our team has crafted a plan specifically tailored to your parenting needs. Ensure a brighter future for you and your child today.',
      button: 'Continue',
    },
    Summary: {
      heading: 'BASED ON YOUR ANSWERS, WE RECOMMEND A:',
      resultsTitle: 'Inner critic score',
      suggestedPlan: 'Parenting empowerment',
      issue: 'parental excellence',
      summary: [
        {
          heading: 'Rejection anxiety',
          description:
            'You may be limiting yourself and holding yourself back because of a fear of being rejected.',
        },
        {
          heading: 'Overwhelming self-doubt',
          description:
            'Based on your scores, it seems that you have low self-confidence in yourself and your abilities.',
        },
        {
          heading: 'Perfectionism',
          description:
            'It appears that you might be a perfectionist and are never quite satisfied with your work.',
        },
        {
          heading: 'Powerful inner critic',
          description:
            'You frequently criticize yourself harshly for any deviations from your plans.',
        },
      ],
    },
    Purchase: {
      suggestedPlan: 'Self-Improvement',
      promise: 'boost self-esteem',
      subtitle:
        'Fast-track your workflow, boost your sales, streamline your operations and and stay ahead of the curve.',
      benefits: [
        {
          heading: 'Build parental confidence',
          description:
            'Equip yourself with the tools to face parenting challenges head-on',
        },
        {
          heading: 'Enhance your efficiency',
          description:
            'Master the art of multitasking and time management to be there for your child',
        },
        {
          heading: 'Solidify parental resolve',
          description:
            'Effective strategies for consistent parenting, even when the going gets tough',
        },
        {
          heading: 'Alleviate parental stress',
          description:
            'Methods for managing stress, ensuring a calmer home environment for your child',
        },
        {
          heading: "Become your child's hero",
          description:
            'Long-term strategies to build a stable, loving home for your child to thrive in',
        },
        {
          heading: 'Elevate family dynamics',
          description:
            'Strengthen communication and empathy within the family, creating a harmony',
        },
      ],

      chart: {
        start: 'Your Insecurity Level',
        end: 'Your Target Level',
      },
      newspaper:
        'Transform your life and reach your goals with a customized plan to eliminate self-doubt and improve your relationships, social life, and career.\n \nTake the first step now towards becoming your best self.',
      scienceBacked: [
        {
          image: '/static/icons/cash.png',
          heading: 'Save time and money',
          description:
            'Streamline your workflow and optimize your resources. Our efficient tools and automated processes will save you valuable time and money, allowing you to focus on what truly matters for your business growth.',
        },
        {
          image: '/static/icons/relieved.png',
          heading: 'Increase conversion rate',
          description:
            'Experience a significant increase in your conversion rate. Our proven strategies and techniques will optimize your marketing efforts, resulting in higher conversion rates and greater business success.',
        },
        {
          image: '/static/icons/bed.png',
          heading: 'Boosted email revenue',
          description:
            'Unlock the potential of your email marketing campaigns. Our AI-based insights and personalized recommendations will help you craft compelling emails that engage your audience and drive revenue growth.',
        },
        {
          image: '/static/icons/brain.png',
          heading: 'Ad concepts and angles',
          description:
            'Stand out from the competition. Our AI-powered platform generates innovative ad concepts and angles that capture attention, drive engagement, and ultimately boost your advertising effectiveness.',
        },
      ],
    },
  };
};
